@charset "UTF-8";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~@/src/plugins/keenthemes-icons/font/ki.css";
@import "~apexcharts/dist/apexcharts.css";
@import "~chart.js/dist/Chart.css";
@import "~@/src/plugins/formvalidation/dist/css/formValidation.css";
@import "~tempusdominus-bootstrap-4/build/css/tempusdominus-bootstrap-4.css";
@import "~bootstrap-datepicker/dist/css/bootstrap-datepicker3.css";
@import "~bootstrap-timepicker/css/bootstrap-timepicker.css";
@import "~bootstrap-daterangepicker/daterangepicker.css";
@import "~bootstrap-touchspin/dist/jquery.bootstrap-touchspin.css";
@import "~bootstrap-select/dist/css/bootstrap-select.css";
@import "~bootstrap-switch/dist/css/bootstrap3/bootstrap-switch.css";
@import "~select2/dist/css/select2.css";
@import "~ion-rangeslider/css/ion.rangeSlider.css";
@import "~nouislider/distribute/nouislider.css";
@import "~owl.carousel/dist/assets/owl.carousel.css";
@import "~owl.carousel/dist/assets/owl.theme.default.css";
@import "~dropzone/dist/dropzone.css";
@import "~quill/dist/quill.snow.css";
@import "~summernote/dist/summernote.css";
@import "~bootstrap-markdown/css/bootstrap-markdown.min.css";
@import "~animate.css/animate.css";
@import "~toastr/build/toastr.css";
@import "~dual-listbox/dist/dual-listbox.css";
@import "~sweetalert2/dist/sweetalert2.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "~@/src/plugins/flaticon/flaticon.css";
@import "~@/src/plugins/flaticon2/flaticon.css";
@import "~socicon/css/socicon.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
:root {
  --tagify-dd-color-primary: rgb(53,149,246);
  --tagify-dd-bg-color: white;
}

.tagify {
  --tags-border-color: #DDD;
  --tags-hover-border-color: #CCC;
  --tags-focus-border-color: #3595f6;
  --tag-bg: #E5E5E5;
  --tag-hover: #D3E2E2;
  --tag-text-color: black;
  --tag-text-color--edit: black;
  --tag-pad: 0.3em 0.5em;
  --tag-inset-shadow-size: 1.1em;
  --tag-invalid-color: #D39494;
  --tag-invalid-bg: rgba(211, 148, 148, 0.5);
  --tag-remove-bg: rgba(211, 148, 148, 0.3);
  --tag-remove-btn-color: black;
  --tag-remove-btn-bg: none;
  --tag-remove-btn-bg--hover: #c77777;
  --input-color: inherit;
  --tag--min-width: 1ch;
  --tag--max-width: auto;
  --tag-hide-transition: 0.3s;
  --placeholder-color: rgba(0, 0, 0, 0.4);
  --placeholder-color-focus: rgba(0, 0, 0, 0.25);
  --loader-size: .8em;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  border: 1px solid #DDD;
  border: 1px solid var(--tags-border-color);
  padding: 0;
  line-height: 1.1;
  cursor: text;
  outline: none;
  position: relative;
  box-sizing: border-box;
  transition: 0.1s;
}
@keyframes tags--bump {
  30% {
    transform: scale(1.2);
  }
}
@keyframes rotateLoader {
  to {
    transform: rotate(1turn);
  }
}
.tagify:hover {
  border-color: #CCC;
  border-color: var(--tags-hover-border-color);
}
.tagify.tagify--focus {
  transition: 0s;
  border-color: #3595f6;
  border-color: var(--tags-focus-border-color);
}
.tagify[readonly]:not(.tagify--mix) {
  cursor: default;
}
.tagify[readonly]:not(.tagify--mix) > .tagify__input {
  visibility: hidden;
  width: 0;
  margin: 5px 0;
}
.tagify[readonly]:not(.tagify--mix) .tagify__tag > div {
  padding: 0.3em 0.5em;
  padding: var(--tag-pad);
}
.tagify[readonly]:not(.tagify--mix) .tagify__tag > div::before {
  background: linear-gradient(45deg, var(--tag-bg) 25%, transparent 25%, transparent 50%, var(--tag-bg) 50%, var(--tag-bg) 75%, transparent 75%, transparent) 0/5px 5px;
  box-shadow: none;
  filter: brightness(0.95);
}
.tagify[readonly] .tagify__tag__removeBtn {
  display: none;
}
.tagify--loading .tagify__input::before {
  content: none;
}
.tagify--loading .tagify__input::after {
  content: "";
  vertical-align: middle;
  opacity: 1;
  width: 0.7em;
  height: 0.7em;
  width: var(--loader-size);
  height: var(--loader-size);
  border: 3px solid;
  border-color: #EEE #BBB #888 transparent;
  border-radius: 50%;
  animation: rotateLoader 0.4s infinite linear;
  margin: -2px 0 -2px 0.5em;
}
.tagify--loading .tagify__input:empty::after {
  margin-left: 0;
}
.tagify + input,
.tagify + textarea {
  display: none !important;
}
.tagify__tag {
  display: inline-flex;
  align-items: center;
  margin: 5px 0 5px 5px;
  position: relative;
  z-index: 1;
  outline: none;
  cursor: default;
  transition: 0.13s ease-out;
}
.tagify__tag > div {
  vertical-align: top;
  box-sizing: border-box;
  max-width: 100%;
  padding: 0.3em 0.5em;
  padding: var(--tag-pad, 0.3em 0.5em);
  color: black;
  color: var(--tag-text-color, black);
  line-height: inherit;
  border-radius: 3px;
  white-space: nowrap;
  transition: 0.13s ease-out;
}
.tagify__tag > div > * {
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: top;
  min-width: 1ch;
  max-width: auto;
  min-width: var(--tag--min-width, 1ch);
  max-width: var(--tag--max-width, auto);
  transition: 0.8s ease, 0.1s color;
}
.tagify__tag > div > *[contenteditable] {
  outline: none;
  user-select: text;
  cursor: text;
  margin: -2px;
  padding: 2px;
  max-width: 350px;
}
.tagify__tag > div::before {
  content: "";
  position: absolute;
  border-radius: inherit;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  pointer-events: none;
  transition: 120ms ease;
  animation: tags--bump 0.3s ease-out 1;
  box-shadow: 0 0 0 1.1em #E5E5E5 inset;
  box-shadow: 0 0 0 var(--tag-inset-shadow-size, 1.1em) var(--tag-bg, #E5E5E5) inset;
}
.tagify__tag:hover:not([readonly]) div::before {
  top: -2px;
  right: -2px;
  bottom: -2px;
  left: -2px;
  box-shadow: 0 0 0 1.1em #D3E2E2 inset;
  box-shadow: 0 0 0 var(--tag-inset-shadow-size, 1.1em) var(--tag-hover, #D3E2E2) inset;
}
.tagify__tag--loading {
  pointer-events: none;
}
.tagify__tag--loading .tagify__tag__removeBtn {
  display: none;
}
.tagify__tag--loading::after {
  --loader-size: .4em;
  content: "";
  vertical-align: middle;
  opacity: 1;
  width: 0.7em;
  height: 0.7em;
  width: var(--loader-size);
  height: var(--loader-size);
  border: 3px solid;
  border-color: #EEE #BBB #888 transparent;
  border-radius: 50%;
  animation: rotateLoader 0.4s infinite linear;
  margin: 0 0.5em 0 -0.1em;
}
.tagify__tag--flash div::before {
  animation: none;
}
.tagify__tag--hide {
  width: 0 !important;
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
  opacity: 0;
  transform: scale(0);
  transition: 0.3s;
  transition: var(--tag-hide-transition, 0.3s);
  pointer-events: none;
}
.tagify__tag--hide > div > * {
  white-space: nowrap;
}
.tagify__tag.tagify--noAnim > div::before {
  animation: none;
}
.tagify__tag.tagify--notAllowed:not(.tagify__tag--editable) div > span {
  opacity: 0.5;
}
.tagify__tag.tagify--notAllowed:not(.tagify__tag--editable) div::before {
  box-shadow: 0 0 0 1.1em rgba(211, 148, 148, 0.5) inset !important;
  box-shadow: 0 0 0 var(--tag-inset-shadow-size, 1.1em) var(--tag-invalid-bg, rgba(211, 148, 148, 0.5)) inset !important;
  transition: 0.2s;
}
.tagify__tag[readonly] .tagify__tag__removeBtn {
  display: none;
}
.tagify__tag[readonly] > div::before {
  background: linear-gradient(45deg, var(--tag-bg) 25%, transparent 25%, transparent 50%, var(--tag-bg) 50%, var(--tag-bg) 75%, transparent 75%, transparent) 0/5px 5px;
  box-shadow: none;
  filter: brightness(0.95);
}
.tagify__tag--editable > div {
  color: black;
  color: var(--tag-text-color--edit, black);
}
.tagify__tag--editable > div::before {
  box-shadow: 0 0 0 2px #D3E2E2 inset !important;
  box-shadow: 0 0 0 2px var(--tag-hover, #D3E2E2) inset !important;
}
.tagify__tag--editable > .tagify__tag__removeBtn {
  pointer-events: none;
}
.tagify__tag--editable > .tagify__tag__removeBtn::after {
  opacity: 0;
  transform: translateX(100%) translateX(5px);
}
.tagify__tag--editable.tagify--invalid > div::before {
  box-shadow: 0 0 0 2px #D39494 inset !important;
  box-shadow: 0 0 0 2px var(--tag-invalid-color, #D39494) inset !important;
}
.tagify__tag__removeBtn {
  order: 5;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  cursor: pointer;
  font: 14px/1 Arial;
  background: none;
  background: var(--tag-remove-btn-bg, none);
  color: black;
  color: var(--tag-remove-btn-color, black);
  width: 14px;
  height: 14px;
  margin-right: 4.6666666667px;
  margin-left: -4.6666666667px;
  overflow: hidden;
  transition: 0.2s ease-out;
}
.tagify__tag__removeBtn::after {
  content: "×";
  transition: 0.3s, color 0s;
}
.tagify__tag__removeBtn:hover {
  color: white;
  background: #c77777;
  background: var(--tag-remove-btn-bg--hover, #c77777);
}
.tagify__tag__removeBtn:hover + div > span {
  opacity: 0.5;
}
.tagify__tag__removeBtn:hover + div::before {
  box-shadow: 0 0 0 1.1em rgba(211, 148, 148, 0.3) inset !important;
  box-shadow: 0 0 0 var(--tag-inset-shadow-size, 1.1em) var(--tag-remove-bg, rgba(211, 148, 148, 0.3)) inset !important;
  transition: box-shadow 0.2s;
}
.tagify:not(.tagify--mix) .tagify__input br {
  display: none;
}
.tagify:not(.tagify--mix) .tagify__input * {
  display: inline;
  white-space: nowrap;
}
.tagify__input {
  flex-grow: 1;
  display: inline-block;
  min-width: 110px;
  margin: 5px;
  padding: 0.3em 0.5em;
  padding: var(--tag-pad, 0.3em 0.5em);
  line-height: inherit;
  position: relative;
  white-space: pre-wrap;
  color: inherit;
  color: var(--input-color, inherit);
  box-sizing: inherit;
  /* Seems firefox newer versions don't need this any more
          @supports ( -moz-appearance:none ){
              &::before{
                  line-height: inherit;
                  position:relative;
              }
          }
  */
  /*
      in "mix mode" the tags are inside the "input" element
  */
}
@-moz-document url-prefix() {}
.tagify__input:empty::before {
  transition: 0.2s ease-out;
  opacity: 1;
  transform: none;
  display: inline-block;
  width: auto;
}
.tagify--mix .tagify__input:empty::before {
  display: inline-block;
}
.tagify__input:focus {
  outline: none;
}
.tagify__input:focus::before {
  transition: 0.2s ease-out;
  opacity: 0;
  transform: translatex(6px);
  /* ALL MS BROWSERS: hide placeholder (on focus) otherwise the caret is places after it, which is weird */
  /* IE10+ CSS styles go here */
  /* IE Edge 12+ CSS styles go here */
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .tagify__input:focus::before {
    display: none;
  }
}
@supports (-ms-ime-align: auto) {
  .tagify__input:focus::before {
    display: none;
  }
}
.tagify__input:focus:empty::before {
  transition: 0.2s ease-out;
  opacity: 1;
  transform: none;
  color: rgba(0, 0, 0, 0.25);
  color: var(--placeholder-color-focus);
}
@-moz-document url-prefix() {
  .tagify__input:focus:empty::after {
    display: none;
  }
}
.tagify__input::before {
  content: attr(data-placeholder);
  height: 1em;
  line-height: 1em;
  margin: auto 0;
  z-index: 1;
  color: rgba(0, 0, 0, 0.4);
  color: var(--placeholder-color);
  white-space: nowrap;
  pointer-events: none;
  opacity: 0;
  position: absolute;
}
.tagify--mix .tagify__input::before {
  display: none;
  position: static;
  line-height: inherit;
}
.tagify__input::after {
  content: attr(data-suggest);
  display: inline-block;
  white-space: pre;
  /* allows spaces at the beginning */
  color: black;
  opacity: 0.3;
  pointer-events: none;
  max-width: 100px;
}
.tagify__input .tagify__tag {
  margin: 0;
}
.tagify__input .tagify__tag > div {
  padding-top: 0;
  padding-bottom: 0;
}
.tagify--mix {
  display: block;
}
.tagify--mix .tagify__input {
  padding: 5px;
  margin: 0;
  width: 100%;
  height: 100%;
  line-height: 1.5;
}
.tagify--mix .tagify__input::before {
  height: auto;
}
.tagify--mix .tagify__input::after {
  content: none;
}
.tagify--select::after {
  content: ">";
  opacity: 0.5;
  position: absolute;
  top: 50%;
  right: 0;
  bottom: 0;
  font: 16px monospace;
  line-height: 8px;
  height: 8px;
  pointer-events: none;
  transform: translate(-150%, -50%) scaleX(1.2) rotate(90deg);
  transition: 0.2s ease-in-out;
}
.tagify--select[aria-expanded=true]::after {
  transform: translate(-150%, -50%) rotate(270deg) scaleY(1.2);
}
.tagify--select .tagify__tag {
  position: absolute;
  top: 0;
  right: 1.8em;
  bottom: 0;
}
.tagify--select .tagify__tag div {
  display: none;
}
.tagify--select .tagify__input {
  width: 100%;
}
.tagify--invalid {
  --tags-border-color: #D39494;
}
.tagify__dropdown {
  position: absolute;
  z-index: 9999;
  transform: translateY(1px);
  overflow: hidden;
}
.tagify__dropdown[placement=top] {
  margin-top: 0;
  transform: translateY(-100%);
}
.tagify__dropdown[placement=top] .tagify__dropdown__wrapper {
  border-top-width: 1px;
  border-bottom-width: 0;
}
.tagify__dropdown[position=text] {
  box-shadow: 0 0 0 3px rgba(var(--tagify-dd-color-primary), 0.1);
  font-size: 0.9em;
}
.tagify__dropdown[position=text] .tagify__dropdown__wrapper {
  border-width: 1px;
}
.tagify__dropdown__wrapper {
  max-height: 300px;
  overflow: hidden;
  background: white;
  background: var(--tagify-dd-bg-color);
  border: 1px solid #3595f6;
  border-color: var(--tagify-dd-color-primary);
  border-top-width: 0;
  box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.2);
  transition: 0.25s cubic-bezier(0, 1, 0.5, 1);
}
.tagify__dropdown__wrapper:hover {
  overflow: auto;
}
.tagify__dropdown--initial .tagify__dropdown__wrapper {
  max-height: 20px;
  transform: translateY(-1em);
}
.tagify__dropdown--initial[placement=top] .tagify__dropdown__wrapper {
  transform: translateY(2em);
}
.tagify__dropdown__item {
  box-sizing: inherit;
  padding: 0.3em 0.5em;
  margin: 1px;
  cursor: pointer;
  border-radius: 2px;
  position: relative;
  outline: none;
}
.tagify__dropdown__item--active {
  background: #3595f6;
  background: var(--tagify-dd-color-primary);
  color: white;
}
.tagify__dropdown__item:active {
  filter: brightness(105%);
}